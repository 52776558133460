import React from "react"
import { Link, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import Services from "../components/services"
import { Container, Row, Col } from "react-bootstrap"

// Styles
import serviceStyles from "../pages/angebot.module.scss"

export const query = graphql`
    query serviceTemplate($url: String!) {
        heroBackground: file(relativePath: { regex: "/rainbow/" }) {
            childImageSharp {
                fluid
                {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        logo: file(relativePath: { regex: "/logo_transparent/" }) {
            childImageSharp {
                fluid
                {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        strapiOffer {
            heroSubtitle
            heroTitle
            intro
            introTitle
        }
        strapiServices( urlSlug: { eq: $url } ) {
            position
            description
            urlSlug
            title
            serviceType
        }

    }
`

const serviceTemplate = ({ data }) => {

    const service = data.strapiServices
    const offer = data.strapiOffer

    return (
        <Layout>
            <SEO title="Angebot" />
            {/* <BackgroundImage
                className={`backgroundImage`}
                fluid={data.heroBackground.childImageSharp.fluid}
            >
                <Container className={`hero`} />
            </BackgroundImage> */}

            {/* <Container className={`heroTextBox mainMid`}>
                <Row className={`justify-content-center`}>
                    <Col>
                        <h1 className={`smallH1`}>{offer.heroTitle}</h1>
                        <span className={`headline`}>{offer.heroSubtitle}</span>
                    </Col>
                </Row>
            </Container> */}

            <Container fluid className={` ${serviceStyles.serviceBackground} `}>
                <Container className={`pt-5 ${serviceStyles.descriptionBox}`}>
                    <Row>
                        <Col>
                            <Container id="description" className={`my-5`}>
                                <h1 className={`text-center mb-5`}>
                                    {service.title}
                                </h1>
                                <ReactMarkdown source={service.description} escapeHtml={false} />
                                {/* <br /><br /> */}
                                {/* <p><small>{service.serviceType === "Psychotherapie" ? "Psychotherapie" : ""}</small></p> */}
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </Container>

            {/* Section 2 */}
            <Container id="title" className={`mainMid my-5`}>
                <Row>
                    <Col className={`text-center`}>
                        <h2>
                            {offer.introTitle}
                        </h2>
                    </Col>
                </Row>
                <Row>
                    <Col className={`text-center`}>
                        <ReactMarkdown source={offer.intro} escapeHtml={false} />
                    </Col>
                </Row>

                <Services useOpacity={true} justifyLeft={false}/>

            </Container>
        </Layout>
    )
}

export default serviceTemplate
